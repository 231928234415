import { Card, Table } from 'react-bootstrap';
import { MapInfoBox, MapInfoBoxContainer } from '../../generic/mapping/MapBoxes';
import { Point, Polygon } from 'geojson';
import { useFetcher } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { LatLng } from 'leaflet';
import * as Terraformer from '@terraformer/wkt';
import { LoggedInUserContext } from '../../App';

class MinMaxMeanStats {
  max: number;
  min: number;
  mean: number;

  constructor(max: number, min: number, mean: number) {
    this.max = max;
    this.min = min;
    this.mean = mean;
  }
}

export function MapPointInfoBox({ point }: { point: Point }): JSX.Element {
  const user = useContext(LoggedInUserContext);
  const [pointData, setPointData] = useState<Map<string, any> | null>();

  const bounds = new LatLng(point.coordinates[1], point.coordinates[0]).toBounds(4500);
  const selectionPoly: Polygon = {
    type: 'Polygon',
    coordinates: [
      [
        [bounds.getNorthEast().lat, bounds.getNorthEast().lng],
        [bounds.getNorthWest().lat, bounds.getNorthWest().lng],
        [bounds.getSouthWest().lat, bounds.getSouthWest().lng],
        [bounds.getSouthEast().lat, bounds.getSouthEast().lng],
        [bounds.getNorthEast().lat, bounds.getNorthEast().lng],
      ],
    ],
  };

  useEffect(() => {
    user?.apiClient.getWFSClient().queryWFS('geonode:nsrdb_metadata', 'within(geometry, ' + Terraformer.geojsonToWKT(selectionPoly) + ')')
      .then((data) => {
        if (data) {
          if (data.features.length === 0) {
            setPointData(null);
            return;
          }
          const featureProps = data.features[0].properties;
          const reducedProps = new Map<string, any>();
          const minMaxMeanProps = new Set<string>();
          Object.keys(featureProps).forEach(key => {

            // TODO: Find better property blocker method
            if (key === 'ogc_fid' || key === 'location_id') {
              return;
            }

            if (key.endsWith('_max') || key.endsWith('_min')) {
              minMaxMeanProps.add(key.slice(0, -4));
            } else if (key.endsWith('_mean')) {
              minMaxMeanProps.add(key.slice(0, -5));
            } else {
              reducedProps.set(key, featureProps[key]);
            }
          });

          minMaxMeanProps.forEach(key => {
            reducedProps.set(key, new MinMaxMeanStats(parseFloat(featureProps[key + '_min']), parseFloat(featureProps[key + '_max']), parseFloat(featureProps[key + '_mean'])));
          });

          setPointData(reducedProps);
        }
      });
  }, [point]);


  return (
    <MapInfoBoxContainer>
      <MapInfoBox>
        <Card.Title>Clicked Point Info:</Card.Title>
        <Card.Text>Location: {point.coordinates.join(', ')}</Card.Text>
        {
          pointData && Array.from(pointData).filter(([_, val]) => !(val instanceof MinMaxMeanStats)).map(([key, val]) => <Card.Text key={key}>{key}: {val}</Card.Text>)
        }
        <Table>
          <tbody>
            <tr>
              <td>Metric</td>
              <td>Min</td>
              <td>Max</td>
              <td>Mean</td>
            </tr>
            {
              pointData ? Array.from(pointData).filter(([_, val]) => val instanceof MinMaxMeanStats)
                .map(([key, val]) => <tr key={key}><td>{key}</td><td>{val.min}</td><td>{val.max}</td><td>{val.mean.toFixed(2)}</td></tr>)
                : "Loading point metrics..."
            }
          </tbody>
        </Table>
      </MapInfoBox>
    </MapInfoBoxContainer>
  );
}
