import { Col, Row } from 'react-bootstrap';
import DiscoveryToolSidebar from './DiscoveryToolSidebar';
import ProjectMap from '../projects/ProjectMap';
import { Waterbody } from '../../utils/WaterbodyClient';
import { useLoaderData, useNavigate, useSearchParams, useSubmit } from 'react-router-dom';
import { WaterbodyMarkerList } from './WaterbodyMarkerList';
import { DraggableWaterbodyMarker } from './DragableMarker';
import { useMapEvents } from 'react-leaflet';
import { createControlComponent } from '@react-leaflet/core';
import { Control, ControlOptions, DomEvent, DomUtil, Map } from 'leaflet';
import { useContext, useEffect, useState } from 'react';
import { LoggedInUserContext } from '../../App';
import { MapSidebar } from '../../generic/Layout';
import { DATA_LAYERS } from '../../generic/mapping/DataLayersControl';
import { MapPointInfoBox } from '../../generic/mapping/MapPointInfoBox';

function NewWaterbodyEvent(): JSX.Element {
  const nav = useNavigate();
  useMapEvents({
    click(e) {
      nav(`/discover/new?lat=${e.latlng.lat}&lng=${e.latlng.lng}`);
    }
  })

  return <></>;
}

type QuickCreateModeParams = ControlOptions & {
  onToggle: (state: boolean) => (void);
}

// For reference see https://github.com/Leaflet/Leaflet/blob/main/src/control/Control.Zoom.js
const QuickCreateMode = createControlComponent((props: QuickCreateModeParams) => {
  const controlInstance = Control.extend({
    onAdd: function (map: Map) {
      const container = DomUtil.create('div', 'form-check form-switch');

      const toggle = DomUtil.create('input', 'form-check-input', container);
      toggle.type = 'checkbox';
      toggle.role = 'switch';

      DomEvent.disableClickPropagation(container);
      DomEvent.on(toggle, 'click', () => props.onToggle(toggle.checked))

      const label = DomUtil.create('label', 'form-check-label', container);
      label.textContent = 'Quick Create Mode';

      return container;
    },

    onRemove: function () { }
  });

  return new controlInstance(props);
})

export function DiscoveryTool() {
  const nav = useNavigate();
  const submit = useSubmit();
  const loggedInUser = useContext(LoggedInUserContext);
  const [searchParams] = useSearchParams();
  const [quickCreateMode, setQuickCreateMode] = useState(false);
  const loadedWaterbody = useLoaderData() as Waterbody | undefined;
  const [selectedWaterbody, setSelectedWaterbody] = useState<Waterbody | undefined>(undefined);

  // Logic for deciding which waterbody to load.
  useEffect(() => {
    // If we loaded a waterbody from the db, always use that. This should never coincide with non-null lat-lng.
    if (loadedWaterbody) {
      setSelectedWaterbody(loadedWaterbody);
      return;
    }

    // This should only occur when we are trying to create a new waterbody.
    if (searchParams.get("lat") && searchParams.get("lng")) {
      const newWaterbody = new Waterbody({ type: 'Point', coordinates: [parseFloat(searchParams.get("lat") as string), parseFloat(searchParams.get("lng") as string)] }, undefined, undefined, loggedInUser?.username);
  
      if (quickCreateMode) {
        // TODO: This code is copied from elsewhere, and should be unified.
        loggedInUser?.apiClient.getWaterbodyClient().createOrUpdateWaterbody(newWaterbody).then(wb => {
          submit({ intent: "save" }, {
            method: "put",
            action: "/data/waterbodies/" + wb.id,
          });
          nav('/discover/');
        });

      } else {
        // Only set the selected waterbody when not in quickCreateMode: we don't want the sidebar to pop up and immediately go away when the save completes.
        setSelectedWaterbody(newWaterbody);
        return;
      }

    }

    setSelectedWaterbody(undefined);
  }, [loadedWaterbody, searchParams, loggedInUser, quickCreateMode, submit, nav]);

  // See App.tsx for class definitions
  return (
    <Row className='flex-fill' style={{ minHeight: '0' }}>
      {selectedWaterbody ?
        <MapSidebar>
          <DiscoveryToolSidebar waterbody={selectedWaterbody} />
        </MapSidebar> : <></>}

      <Col className='gx-0'>
        <ProjectMap zoomOnDblclk={false} disabledLayers={[DATA_LAYERS.waterbodies]} >
          <QuickCreateMode position='bottomleft' onToggle={setQuickCreateMode} />
          {selectedWaterbody && <MapPointInfoBox point={selectedWaterbody.location} />}
          <WaterbodyMarkerList skipWaterbody={selectedWaterbody} selectNewWaterbody={(wb) => nav(`/discover/edit/${wb.id}`)} />
          {selectedWaterbody ? <DraggableWaterbodyMarker waterbody={selectedWaterbody} /> : <></>}
          <NewWaterbodyEvent />
        </ProjectMap>
      </Col>
    </Row>
  );
}
